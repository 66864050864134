.hidemobile {
  display: none;
}

.Topheadercontainer {
  background-color: #0093e9;
  margin-top: -1.5vh;
}

@media screen and (min-width: 767px) {
  .hidemobile {
    display: flex;
  }
  .Topheadercontainer {
    display: none;
  }
}
