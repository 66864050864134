@import url("https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900");
.liquid {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.liquid h2 {
  position: absolute;
}
.liquid h2:nth-child(1) {
  color: #fff;
  text-shadow: -2px 2px 0px #183954, -4px 4px 0px #183954, -6px 6px 0px #183954,
    -8px 8px 0px #183954, -18px 18px 20px rgba(0, 0, 0, 0.5),
    -18px 18px 50px rgba(0, 0, 0, 0.5);
}
.liquid h2:nth-child(2) {
  color: #2196f3;
  animation: animate 3s ease-in-out infinite;
  opacity: 0.5;
}

.liquid h2:nth-child(3) {
  color: #2196f3;
  opacity: 0.5;
  animation: animate 6s ease-in-out infinite;
}
.liquid h2:nth-child(4) {
  color: #2196f3;
  animation: animate 4s ease-in-out infinite;
}
@keyframes animate {
  0%,
  100% {
    clip-path: polygon(
      0 46%,
      15% 45%,
      34% 52%,
      50% 61%,
      68% 65%,
      85% 61%,
      100% 53%,
      100% 100%,
      0% 100%
    );
  }
  50% {
    clip-path: polygon(
      3% 59%,
      17% 67%,
      36% 70%,
      51% 66%,
      65% 56%,
      81% 49%,
      99% 45%,
      100% 100%,
      0% 100%
    );
  }
}
