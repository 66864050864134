.video {
  width: 100%;
  height: 100%;
}

@media screen and (min-width: 767px) {
  .video {
    top: 0;
    left: 0;
    width: 600px;
    height:  450px;
    margin-left: calc(50% - (200vh / 2));
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }
}
