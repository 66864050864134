.mapIframe {
  margin-top: 30px;
  width: 100%;
  height: 100%;
}

@media screen and (min-width: 767px) {
  .mapIframe {
    width: 600px;
    height: 450px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    margin-left: calc(50% - (100vh / 2));

  }
}
